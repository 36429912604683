// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backstory-js": () => import("./../../../src/pages/backstory.js" /* webpackChunkName: "component---src-pages-backstory-js" */),
  "component---src-pages-customize-your-sax-js": () => import("./../../../src/pages/customize-your-sax.js" /* webpackChunkName: "component---src-pages-customize-your-sax-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-read-more-js": () => import("./../../../src/pages/read-more.js" /* webpackChunkName: "component---src-pages-read-more-js" */),
  "component---src-pages-storyworld-js": () => import("./../../../src/pages/storyworld.js" /* webpackChunkName: "component---src-pages-storyworld-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

